const subscriptions = {};

export function addSubscription(stateName, stateUpdater) {
  subscriptions[stateName] = stateUpdater;
  return unsubscribe.bind(this, stateName);
}

export function runStateUpdater(stateName, ...args) {
  return subscriptions[stateName]?.(...args);
}

export function unsubscribe(stateName) {
  delete subscriptions[stateName];
}
