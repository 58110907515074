import { toSnakeCase, getStatForKeyword } from "utils";

export function resistanceBuff(keyword) {
  // 22.50%% resistance to smashing damage
  // 15.00%% resistance to toxic damage
  // 20.00%% resistance to jump speed
  // 20.00%% resistance to jump height
  // +20.00%% resistance to recharge
  return function () {
    return {
      run: (phrase) => {
        this.effect.magnitude = this.parseValue(this.pop(1, this.hold)[0]);
        phrase.shift(); // drop 'to'

        const [adj, noun] = phrase;

        if (noun === "damage") {
          this.effect.stat = getStatForKeyword(toSnakeCase(adj, "resistance"));
        } else if (getStatForKeyword(toSnakeCase(adj, keyword), false)) {
          this.effect.stat = getStatForKeyword(toSnakeCase(adj, keyword));
        } else if (getStatForKeyword(toSnakeCase(adj, noun, keyword))) {
          this.effect.stat = getStatForKeyword(toSnakeCase(adj, noun, keyword));
        } else {
          console.error("Did not find stat for: ", adj, noun, keyword);
        }
      },
    };
  };
}

export function statBuff(keyword) {
  // +20.00%% to hit
  // -60.00%% endurance
  return function () {
    return {
      run: (phrase) => {
        let [mag] = this.pop(1, this.hold);
        if (mag === "magnitude") {
          mag = this.pop(1, this.hold)[0];
        } else if (mag === "max") {
          keyword = "max_" + keyword;
          mag = this.pop(1, this.hold)[0];
        }

        this.effect.magnitude = this.parseValue(mag);
        if (getStatForKeyword(toSnakeCase(keyword), false)) {
          this.effect.stat = getStatForKeyword(toSnakeCase(keyword));
        } else {
          this.effect.action = toSnakeCase(keyword);
        }
      },
      validate: () => {
        return !this.hold.includes("chance");
      },
    };
  };
}
