// Attack Types:melee defense, smashing defense, energy defense
export default function attackTypes(word, value) {
  let arr = this.nextItems(1);
  let word1 = value;
  let word2 = arr[0];

  this.parsed.attackTypes = [];

  while (word2 === "defense," || word2 === "defense") {
    this.parsed.attackTypes.push(`${word1} defense`);

    arr.remove();
    arr = this.nextItems(2);
    word1 = arr[0];
    word2 = arr[1];
  }

  if (!this.parsed.attackTypes.length) {
    console.log("No attack types for: ", word, value);
  }
}
