import { getStatForKeyword } from "utils";

export default function taunt() {
  // +400.00%% taunt
  return { run };
}

function run() {
  const [mag] = this.pop(1, this.hold);
  this.effect.magnitude = this.parseValue(mag);
  this.effect.stat = getStatForKeyword("taunt");
}
