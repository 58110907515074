export const powerLevels = [
  1, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 35, 38, 41,
  44, 47, 49,
];

export const powerSlotIndexFromLevel = powerLevels.reduce(
  (acc, level, index) => {
    acc[level] = index;
    return acc;
  },
  {}
);

export const enhancementSlots = [
  3, 3, 5, 5, 7, 7, 9, 9, 11, 11, 13, 13, 15, 15, 17, 17, 19, 19, 21, 21, 23,
  23, 25, 25, 27, 27, 29, 29, 31, 31, 31, 33, 33, 33, 34, 34, 34, 36, 36, 36,
  37, 37, 37, 39, 39, 39, 40, 40, 40, 42, 42, 42, 43, 43, 43, 45, 45, 45, 46,
  46, 46, 48, 48, 48, 50, 50, 50,
];
