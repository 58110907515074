import { getIsInArray, isTimeValue, toCamelCase } from "utils";

const effects = [
  "unresistable",
  "Ignores buffs and enhancements",
  "Only when Accelerated",
  "Only against minions and underlings",
];

const ignore = ["Only using Fiery Embrace"];

const prepositions = {
  for: {
    check: function () {
      return isTimeValue(this.nextItems(2)[1]);
    },
    cb: function () {
      // for 50s
      // for 1m 5s
      this.lastEffect.duration = this.getNextTimeItem();
    },
  },
  on: {
    cb: function () {
      // on target
      const [target] = this.nextItems(1);
      this.lastEffect.target = target;
      this.pop();
    },
  },
  after: {
    cb: function () {
      // after 0.50s delay
      this.lastEffect.delay = this.getNextTimeItem();
      this.pop();
    },
  },
  over: {
    cb: function () {
      this.lastEffect.duration = this.parseValue(this.pop());
    },
  },
};

export default function afterEffects() {
  if (this.lastEffect) {
    addPrepositions.call(this);
    const results = loopArray.call(this, effects, false);
    const ignoreResults = !results && loopArray.call(this, ignore, true);
    return results || ignoreResults;
  } else return false;
}

function loopArray(arr, ignore) {
  let results = this.scanForNextWords(arr);
  let didFind = false;

  while (results.index !== -1) {
    didFind = true;
    const { index, remove } = results;

    if (index > -1) {
      remove();
      if (ignore) {
        // Remove effect (ie: Fiery Embrace stat only applies if you have Fiery Embrace, don't include it in the raw data)
        this.parsed.effects.pop();
        return true;
      } else {
        const effect = this.lastEffect;
        if (!effect.attributes) {
          effect.attributes = {};
        }

        effect.attributes[toCamelCase(arr[index])] = true;
      }
    }

    results = this.scanForNextWords(arr);
  }

  return didFind;
}

function addPrepositions() {
  let prepCheck = prepositions[this.nextItems(1)[0]];
  while (prepCheck && (!prepCheck.check || prepCheck.check.call(this))) {
    this.pop();
    prepCheck.cb.call(this);
    prepCheck = prepositions[this.nextItems(1)[0]];
  }
}

function getFirstWordOfString(str) {
  const index = str.indexOf(" ");
  return index === -1 ? str : str.substring(0, index);
}

const firstWords = [
  ...effects.map(getFirstWordOfString),
  ...ignore.map(getFirstWordOfString),
  ...Object.keys(prepositions),
];
export const isEffectEnd = getIsInArray(firstWords);
