import standard from "./standard.js";
import statStrings from "./statStrings.js";

export const AverageDamage = standard("averageDamage");
export const DamagePerActivationTime = standard("damagePerActivationTime");
export const DamagePerCastCycle = standard("damagePerCastCycle");
export const ActivationTime = standard("activationTime");
export const RechargeTime = standard("rechargeTime");
export const EnduranceCost = standard("enduranceCost");
export const Accuracy = standard("accuracy");
export const AvailableLevel = standard("availableLevel");
export const PowerType = standard("powerType");
export const AggroType = statStrings("Enemies will not notice this attack");
export const TargetType = statStrings(
  "Target Player",
  "Enemies",
  "Self",
  "Location"
);

export { default as EffectArea } from "./effectArea.js";
export { default as PowerRange } from "./powerRange.js";
export { default as AttackTypes } from "./attackTypes.js";
