import axios from "axios";
import { alphabeticalSort, debounce, additiveMerge } from "utils";
import { runStateUpdater } from "../stateSubscriptions";
import * as outgoingTransformers from "./transformers/outgoing";
import * as incomingTransformers from "./transformers/incoming";
import route from "route.js";

const fetch = axios.create({ baseURL: process.env.REACT_APP_API_BASEURL });

fetch.interceptors.request.use((req) => {
  if (!req.headers.authorization) {
    req.headers.authorization = localStorage.getItem("token");
  }

  return req;
});

fetch.interceptors.response.use(
  (res) => {
    runStateUpdater("clearErrors");
    return res.data;
  },
  (err) => {
    if (err.response?.data?.message === "Invalid token") {
      console.log("INVALID TOKEN!");
      runStateUpdater("clearToken");
      route.push("/");
    } else if (err.response?.data?.context) {
      const { context, message } = err.response.data;
      runStateUpdater("addError", context, message);
    } else if (err.response?.status === 422 && err.response?.data?.error) {
      runStateUpdater("createNotification", err.response?.data?.error, "error");
    } else {
      console.log("ERR MSG: ", err.response);
      runStateUpdater(
        "createNotification",
        "Whoops, something unexpected happen, please try again later.",
        "error"
      );
    }

    throw err;
  }
);

const debounceConfig = Object.freeze({ leading: true, wait: 100 });
function getDebounce(cb) {
  return debounce(cb, debounceConfig);
}

function combineCalls(cb) {
  let cached;
  let timeout = null;
  let resolve = null;

  return function (...args) {
    if (args.length) {
      if (!cached) {
        cached = args;
      } else {
        cached = cached.map((arg, i) => additiveMerge(arg, args[i]));
      }
    }
    timeout && clearTimeout(timeout);
    resolve?.();

    return new Promise((res) => {
      resolve = res;
      timeout = setTimeout(() => {
        res(cb.apply(this, cached || args));
        timeout = null;
        resolve = null;
        cached = null;
      }, debounceConfig.wait);
    });
  };
}

export function createStandardCRUD(resource, bypassAdmin) {
  const path = `/${resource}`;
  const admin = !bypassAdmin ? `${path}/admin` : path;

  const transformAndSort = (fetchedResources) => {
    const transformer = incomingTransformers[resource];

    const transformed = transformer
      ? fetchedResources.map(transformer)
      : fetchedResources;

    return alphabeticalSort(transformed);
  };

  return {
    create: getDebounce((newResource) => {
      if (outgoingTransformers[resource]) {
        newResource = outgoingTransformers[resource](newResource);
      }

      return fetch.post(admin, newResource);
    }),
    retrieve: combineCalls(() => {
      return fetch.get(path).then(transformAndSort);
    }),
    retrieveItem: getDebounce((id) => {
      return fetch.get(`${path}/item/${id}`);
    }),
    retrieveWithFilter: combineCalls((filter) => {
      return fetch
        .post(`${path}/getWithFilters`, filter)
        .then(transformAndSort);
    }),
    update: getDebounce((id, updates) => {
      if (updates.id) {
        delete updates.id;
      }

      if (outgoingTransformers[resource]) {
        updates = outgoingTransformers[resource](updates);
      }

      return fetch.put(`${admin}/${id}`, updates);
    }),
    delete: getDebounce((id) => {
      return fetch.delete(`${admin}/${id}`);
    }),
    uploadImage: getDebounce((file) => {
      const formData = new FormData();
      formData.append("image", file);
      return fetch.post("/postImage", formData);
    }),
    fetch: getDebounce((fetchObject) => {
      return fetch(fetchObject);
    }),
  };
}

export const validateToken = getDebounce((token) => {
  return fetch.get("/user", { headers: { authorization: token } });
});

export const login = getDebounce((formData) => {
  return fetch.post("/user/login", formData);
});

export const register = getDebounce((formData) => {
  return fetch.post("/user/register", formData);
});

export const axiosInstance = fetch;
