import { toCamelCase } from "utils";

export default function statStrings(...expectedStrings) {
  return function (word, value) {
    const { index, remove } = this.scanForNextWords(expectedStrings, value);

    if (index > -1) {
      remove();
      this.parsed[toCamelCase(word)] = expectedStrings[index];
    }
  };
}
