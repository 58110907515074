export * as levels from "./levels.js";
export { newCharacter } from "./newCharacter";
export * as shortNames from "./shortNames.js";
export { default as taskForces } from "./taskForces";
export {
  stats,
  keywordToStat,
  damageTypes,
  defenseTypes,
  mezTypes,
  targets,
} from "./stats.js";

export const maxNameLength =
  process.env.REACT_APP_MAX_CHARACTER_NAME_LENGTH || 50;

export const notificationDuration = process.env.REACT_APP_NOTIFICATION_DURATION
  ? Number(process.env.REACT_APP_NOTIFICATION_DURATION)
  : 6000;
