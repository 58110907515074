function parser(cb) {
  function parseValue(allowNullable, v) {
    if (allowNullable && (v === null || v === "")) {
      return null;
    }

    return cb(v);
  }

  const noNullable = parseValue.bind({}, false);
  noNullable.nullable = () => parseValue.bind({}, true);
  return noNullable;
}

export const number = () => parser((v) => Number(v));
export const string = () => parser((v) => v.toString());
export const boolean = () => parser((v) => !!v);

export const object = (instructions) =>
  parser((value) => {
    if (!instructions) {
      return value;
    }

    const obj = { ...value };
    for (let key in obj) {
      if (instructions[key]) {
        obj[key] = instructions[key](obj[key]);
      } else {
        delete obj[key];
      }
    }

    return obj;
  });

export const array = (cb) =>
  parser((v) => {
    if (!Array.isArray(v)) {
      throw new Error(`Expected an array but received type ${typeof v}`);
    }

    return v.filter(cb);
  });
