import { getStatForKeyword, toSnakeCase } from "utils";

export default function debuff() {
  // -11.25%% defense debuff for 10.00s on target
  return { run };
}

function run(word, value) {
  const [mag, stat] = this.pop(2, this.hold);
  this.effect.magnitude = this.parseValue(mag);
  this.effect.stat = getStatForKeyword(toSnakeCase(stat, "debuff"));
}
