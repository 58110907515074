import * as statModules from "./statModules/";
import * as effectModules from "./effectModules/";
import addAfterEffects, { isEffectEnd } from "./afterEffects.js";

import {
  allowTruthy,
  isTimeValue,
  noFunc,
  isTarget as isValueTarget,
  parseValue as utilParseValue,
  isStat,
} from "../";

class Parser {
  parse = (str) => {
    this.reset(str);

    while (this.queue.length) {
      this.effect = {};
      this.analyzeNextWord();

      if (this.currentIndex > this.switchIndex && this.onStats) {
        this.switchModes();
      }
    }

    if (this.hold.length) {
      this.unknown.push(...this.hold);
    }

    return { value: this.parsed, unknown: this.unknown };
  };

  analyzeNextWord = () => {
    const rawWord = this.pop();
    let [word, value] = this.onStats ? rawWord.split(":") : [rawWord];

    if (this.onStats && isTimeValue(value)) {
      let nextItem = this.nextItems(1);
      while (isTimeValue(nextItem[0])) {
        value += nextItem[0];
        nextItem.remove();
        nextItem = this.nextItems(1);
      }
    }

    const command = this.findCommand(word);
    let validated;
    if (command) {
      try {
        const parser = this.modules[command];
        if (this.onStats) {
          validated = true;
          parser.call(this, command, value);
        } else {
          validated = this.parseEffect(parser, command, word);
        }
      } catch (err) {
        console.log("PARSER ERROR: ", err);
      }

      if (validated) {
        this.unknown.push(...this.hold);
        this.hold = [];
      }
    } else {
      this.hold.push(word);
    }
  };

  parseEffect(parser, command, word) {
    const results = parser.call(this, command);
    const validated =
      results &&
      (!results.hasOwnProperty("validate") || results.validate.call(this));

    if (results && validated) {
      const phrase = this.findEffectText();

      if (phrase) {
        results.static && (this.effect = { ...this.effect, ...results.static });
        results.run?.call(this, phrase);

        if (this.effect.stat && !isStat(this.effect.stat)) {
          throw new Error(`Unrecognized stat: ${this.effect.stat}`);
        }
        this.parsed.effects.push(this.effect);
        addAfterEffects.call(this);
      }
    } else {
      this.hold.push(word);
    }

    return validated;
  }

  findEffectText = () => {
    const words = [];

    for (let i = this.queue.length - 1; i >= 0; i--) {
      const word = this.queue[i];
      const isEnd = isEffectEnd(word);

      if (isEnd) {
        this.pop(words.length);
        return words;
      } else {
        words.push(word);
      }
    }
  };

  findTruthyCallback = (cbs, ...args) => {
    for (let i = 0; i < cbs.length; i++) {
      if (cbs[i]?.(...args)) {
        return i;
      }
    }

    return -1;
  };

  nextItems = (count = 1) => {
    return this.getLastItemsFromArray(this.queue, count);
  };

  lastOnHold = (count = 1) => {
    return this.getLastItemsFromArray(this.hold, count);
  };

  getLastItemsFromArray = (arr, count = 1) => {
    if (count === 0) {
      const empty = [];
      empty.remove = noFunc;
      return empty;
    }

    const sliced = arr.slice(arr.length - count);
    sliced.remove = () => {
      this.pop.call(this, count, arr);
      sliced.remove = noFunc;
    };
    return arr === this.queue ? sliced.reverse() : sliced;
  };

  getNextTimeItem = () => {
    let duration = "";
    let hasOpen = false;

    while (isTimeValue(this.nextItems(1)[0]) || hasOpen) {
      const value = this.pop();
      const openIndex = !hasOpen ? value.indexOf("(") : -1;
      const closeIndex = value.indexOf(")");

      !hasOpen &&
        (duration += openIndex > -1 ? value.substring(0, openIndex) : value);
      if (openIndex > -1 && closeIndex === -1) {
        hasOpen = true;
      } else if (hasOpen && closeIndex > -1) {
        hasOpen = false;
      }
    }

    return this.parseValue(duration);
  };

  pop = (count, arr = this.queue) => {
    if (count === 0) {
      return [];
    }

    if (arr === this.queue) {
      this.currentIndex += count || 1;
    }

    return !count ? arr.pop() : arr.splice(arr.length - count, count);
  };

  reset = (str) => {
    this.queue = str.split(" ").reverse().filter(allowTruthy);
    this.unknown = [];
    this.hold = [];
    this.modules = statModules;
    this.currentIndex = 0;

    this.switchIndex =
      this.queue.length - 1 - this.queue.findIndex((s) => s.includes(":"));
    this.parsed = {};
  };

  switchModes = () => {
    if (this.parsed.availableLevel === undefined) {
      this.parsed.availableLevel = 1;
    }

    this.parsed.effects = [];
    this.modules = effectModules;
  };

  scanForNextWords = (arrOfWords, startStr = "") => {
    const startLength = startStr.length ? startStr.split(" ").length : 0;
    for (let i = 0; i < arrOfWords.length; i++) {
      const str = arrOfWords[i];
      const strWordCount = str.split(" ").length - startLength;
      const nextWords = this.nextItems(strWordCount);
      startStr && nextWords.unshift(startStr);
      const strWords = nextWords.join(" ");

      if (str === strWords) {
        return { index: i, remove: nextWords.remove };
      }
    }

    return { index: -1 };
  };

  get onStats() {
    return this.modules === statModules;
  }

  findCommand = (w) => {
    let word = w;
    let command = false;
    for (let i = this.hold.length; i > -1; i--) {
      if (this.modules[word] && this.isValidEffect(word)) {
        const count = this.hold.length - i;
        this.hold.splice(i, count);
        command = word;
      }
      word = this.join(this.hold[i - 1], word);
    }

    return command;
  };

  isValidEffect = (word) => {
    if (this.onStats) {
      return true;
    }

    const { validate } = this.modules[word]?.call(this) || {};

    return !validate || validate.call(this, word);
  };

  join(...words) {
    return words.join("");
  }

  parseDamageTypes(str) {
    return str.split(", ");
  }

  getIndex(n = 0, arr = this.queue) {
    return arr[arr.length - 1 - n];
  }

  getAllInQueueUntil = (word) => {
    const arr = [];

    let next = this.getIndex(0);
    while (next !== word && arr.length < this.queue.length) {
      arr.push(next);
      next = this.getIndex(arr.length);
    }

    arr.remove = this.pop.bind(this, arr.length);
    return arr;
  };

  isTarget = (v) => {
    return isValueTarget(v);
  };

  parseValue = utilParseValue;

  get lastEffect() {
    const { effects } = this.parsed;
    return effects[effects.length - 1];
  }
}

export default new Parser().parse;
