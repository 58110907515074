import { powerLevels } from "./levels";

export const newCharacter = {
  name: "",
  archetype: {},
  powersets: {
    primary: { id: null },
    secondary: { id: null },
    epic: { id: null },
  },
  build: {
    pools: [],
    powerSlots: powerLevels.map((level, i) => ({
      id: i,
      level,
    })),
    customPowerSlots: [],
  },
  activePowerSlot: 0,
  viewingPowerSlot: null,
  viewingPool: null,
  activePowers: {},
  private: true,
  changeCount: 0,
};
