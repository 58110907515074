export const stats = {
  hp: { keywords: ["hit_points", "max_hit_points"] },
  absorption: { keywords: ["absorb"] },
  endurance: { keywords: ["endurance"] },
  regeneration_rate: { keywords: ["regeneration"] },
  endurance_consumption: {},
  recovery_rate: { keywords: ["recovery"] },
  to_hit: { keywords: ["to", "tohit", "hit"] },
  accuracy: { keywords: ["accuracy"] },
  damage_bonus: { keywords: ["all_damage", "damage"] },
  healing_bonus: {},
  healing_received_bonus: {},
  recharge_time_bonus: { keywords: ["recharge", "recharge_time"] },
  endurance_discount: {},
  stealth_radius_pve: { keywords: ["stealth", "stealth_radius"] },
  stealth_radius_pvp: {},
  perception_radius: {},
  range_bonus: { keywords: ["range"] },
  threat_level: {},
  inherent: {},
  level_shift: {},
  run_speed: { keywords: ["run", "running", "running_speed"] },
  max_run_speed: {},
  flying_speed: { keywords: ["fly", "flying", "fly_speed"] },
  max_flying_speed: {},
  jumping_speed: { keywords: ["jump_speed"] },
  max_jumping_speed: {},
  jumping_height: { keywords: ["jump_height"] },
  max_jumping_height: {},
  run_speed_resistance: { keywords: ["running_speed_resistance"] },
  flying_speed_resistance: { keywords: ["fly_speed_resistance"] },
  jumping_speed_resistance: { keywords: ["jump_speed_resistance"] },
  jumping_height_resistance: { keywords: ["jump_height_resistance"] },
  smashing_resistance: {},
  damage_resistance_bonus: {},
  lethal_resistance: {},
  fire_resistance: {},
  cold_resistance: {},
  energy_resistance: {},
  negative_resistance: { keywords: ["negative_energy_resistance"] },
  psionic_resistance: {},
  toxic_resistance: {},
  defense_bonus: { keywords: ["all_defense"] },
  ranged_defense: { keywords: ["defense_debuff"] },
  melee_defense: { keywords: ["defense_debuff"] },
  aoe_defense: { keywords: ["defense_debuff"] },
  smashing_defense: { keywords: ["defense_debuff"] },
  lethal_defense: { keywords: ["defense_debuff"] },
  fire_defense: { keywords: ["defense_debuff"] },
  cold_defense: { keywords: ["defense_debuff"] },
  energy_defense: { keywords: ["defense_debuff"] },
  negative_defense: { keywords: ["defense_debuff", "negative_energy_defense"] },
  psionic_defense: { keywords: ["defense_debuff"] },
  toxic_defense: {},
  regeneration_resistance: {},
  recovery_resistance: {},
  endurance_resistance: {},
  to_hit_resistance: {},
  recharge_time_resistance: { keywords: ["recharge_resistance"] },
  defense_resistance: {},
  hold_protection: {},
  immobilize_protection: {},
  stun_protection: {},
  sleep_protection: {},
  knockback_protection: {
    keywords: ["knock_protection", "knockup_protection"],
  },
  confuse_protection: {},
  terrorize_protection: {},
  repel_protection: {},
  teleport_protection: {},
  hold_resistance: {},
  immobilize_resistance: {},
  stun_resistance: {},
  sleep_resistance: {},
  knockback_resistance: {
    keywords: ["knockup_resistance", "knock_resistance"],
  },
  confuse_resistance: {},
  terrorize_resistance: {},
  repel_resistance: {},
  placate_resistance: {},
  taunt_resistance: { keywords: ["taunt"] },
};

function addToLookup(lookup, key, value) {
  if (lookup[key]) {
    if (Array.isArray(lookup[key]) && !lookup[key].find((v) => v === value)) {
      lookup[key].push(value);
    } else if (lookup[key] !== value) {
      lookup[key] = [lookup[key], value];
    }
  } else {
    lookup[key] = value;
  }
}

export const keywordToStat = Object.entries(stats).reduce(
  (acc, [key, { keywords }]) => {
    addToLookup(acc, key, key);

    if (keywords) {
      keywords.forEach((str) => addToLookup(acc, str, key));
    }

    return acc;
  },
  {}
);

export const damageTypes = {
  lethal: true,
  smashing: true,
  fire: true,
  cold: true,
  energy: true,
  negative: true,
  psionic: true,
  toxic: true,
};

export const defenseTypes = {
  ...damageTypes,
  ranged: true,
  melee: true,
  aoe: true,
};

export const mezTypes = [
  "hold",
  "sleep",
  "stun",
  "immobilize",
  "repel",
  "knock",
  "knockup",
];

export const targets = ["self", "target", "enemies"];
