import { isDamageType } from "utils";

export default function ticks() {
  return {
    terminate: [this.isTarget, "delay"],
    run,
  };
}

function run(phrase) {
  // 4 Ticks of 3.73 energy damage
  // 3 Ticks of 99.96 heal
  const [c] = this.pop(1, this.hold);
  const tickCount = this.parseValue(c);

  const [, magnitude, stat] = phrase;

  this.effect.magnitude = this.parseValue(magnitude);
  this.effect.ticks = this.parseValue(tickCount);

  if (isDamageType(stat)) {
    this.effect.damageType = stat;
    this.effect.action = "damage";
  } else if (stat === "heal") {
    this.effect.action = stat;
  } else {
    console.log("Unknown tick: ", phrase);
  }
}
