import { getStatForKeyword, isDamageType, toSnakeCase } from "utils";

export default function chanceEffect(keyword) {
  return { run };
}

export const knock = chanceEffect("knock");
export const knockup = chanceEffect("knockup");
export const stun = chanceEffect("stun");

function run(phrase) {
  // 60.00% >chance< for 3.00 magnitude [action]
  // 5.00% >chance< for 27.81 [damageType] damage
  const nextItems = this.nextItems(4);
  const [, mag, identifier, stat] = nextItems;

  if (identifier === "magnitude") {
    nextItems.remove();
    magnitude.call(this, mag, stat);
  } else if (isDamageType(identifier)) {
    nextItems.remove();
    damage.call(this, mag, identifier);
  }
}

function magnitude(mag, stat) {
  const [percentage] = this.pop(1, this.hold);
  this.effect.chance = this.parseValue(percentage);
  this.effect.magnitude = this.parseValue(mag);
  this.effect.stat = getStatForKeyword(toSnakeCase(stat, "protection"));
  this.effect.action = stat;
}

function damage(mag, damageType) {
  // 5.00% chance for 27.81 [damageType] damage
  const [percentage] = this.pop(1, this.hold);
  this.effect.chance = this.parseValue(percentage);
  this.effect.magnitude = this.parseValue(mag);
  this.effect.action = "damage";
  this.effect.damageType = damageType;
}
