import { isNumber, isDamageType } from "utils";

export default function damage() {
  // 16.68 fire damage on target
  // 16.68 fire damage on target after 0.5s delay
  return { run, validate };
}

function run(phrase) {
  const [mag, damageType] = this.pop(2, this.hold);

  if (isNumber(mag) && isDamageType(damageType)) {
    this.effect.magnitude = this.parseValue(mag);
    this.effect.damageType = damageType;
    this.effect.action = "damage";
  } else {
    console.log("NOT DMG: ", mag, damageType);
  }
}

function validate() {
  return !this.hold.includes("chance");
}
