const areas = {
  AoE: true,
  Cone: true,
};

export default function effectArea(word, value) {
  if (areas[value]) {
    // Value = AoE / Cone.
    // Next items = [--, 60.00, ft., radius, (255, targets, max)]
    // Cone items = [--, 7.00, ft., radius, 75.00, degree, arc, (5, targets, max)]
    let nextItems = this.nextItems(7);
    let [, magnitude, , , targetCount, , max] = nextItems;

    const effect = {
      area: value,
      magnitude: parseFloat(magnitude),
    };

    if (max[max.length - 1] === ")") {
      effect.maxTargets = Number(targetCount.substring(1));
      this.parsed.effectArea = effect;
      return nextItems.remove();
    } else if (max === "arc") {
      const nextItems = this.nextItems(10);
      let [, , , , arcRadius, , , tCount] = nextItems;
      effect.maxTargets = Number(tCount.substring(1));
      effect.radius = this.parseValue(arcRadius);
      this.parsed.effectArea = effect;
      return nextItems.remove();
    } else {
      this.unknown.push(word, value);
      return console.log("Unknown effect area: ", word, value, nextItems);
    }
  }

  const nextWord = this.nextItems(1);
  if (value === "Location") {
    this.parsed.effectArea = { area: "Location" };
  } else if (value === "Single" && nextWord[0] === "Target") {
    nextWord.remove();
    this.parsed.effectArea = { area: "Single Target" };
  } else {
    console.log("Unknown effect area: ", word, value, nextWord);
  }
}
