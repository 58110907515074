const taskForces = [
  {
    contact: "Positron",
    location: "Steel Canyon",
    coordinates: "(-3136, -80, -501)",
    teamSize: 3,
    levels: { min: 10, max: 15 },
    merits: 60,
    notes:
      "Considered one of the longest TFs in the game, and one of the hardest due to lack of powers. No longer widly used only available in ouroboros. Replaced by two of his.",
    id: 1,
    name: "The Rule of the Three",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
    link: "https://cityofheroes.fandom.com/wiki/Positron_Task_Force",
  },
  {
    contact: "Synapse",
    teamSize: 4,
    location: "Skyway City",
    coordinates: "(110, -19, -5155.5)",
    link: "https://cityofheroes.fandom.com/wiki/Synapse_Task_Force",
    levels: { min: 15, max: 20 },
    merits: 58,
    notes: "Starts in Skyway City",
    id: 2,
    name: "The Fall of the Clockwork King",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Sister Psyche",
    teamSize: 5,
    location: "Ouroboros",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Sister_Psyche_Task_Force",
    levels: { min: 20, max: 25 },
    merits: 50,
    notes: "Starts in Independence Port",
    id: 3,
    name: "Clamor and Destruction",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Moonfire",
    teamSize: 6,
    location: "Striga Isle",
    coordinates: "(-3600 63.5 -388)",
    link: "https://cityofheroes.fandom.com/wiki/Moonfire_Task_Force",
    levels: { min: 23, max: 28 },
    merits: 32,
    notes: "Takes place in Striga Isle.",
    id: 4,
    name: "The Kheldian War",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Citadel",
    teamSize: 6,
    levels: { min: 25, max: 30 },
    merits: 40,
    location: "Talos Island",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Citadel_Task_Force",
    id: 5,
    name: "Citadel's Children",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Ernesto Hess",
    teamSize: 6,
    location: "Striga Isle",
    coordinates: "(-6555.5, 79, 283)",
    link: "https://cityofheroes.fandom.com/wiki/Ernesto_Hess_Task_Force",
    levels: { min: 25, max: 30 },
    merits: 15,
    notes: "Takes place in Striga Isle.",
    id: 6,
    name: "The MegaMech Cometh",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Twilight's Son",
    teamSize: 1,
    location: "Ouroboros",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Twilight%27s_Son_Task_Force",
    levels: { min: 25, max: 34 },
    merits: 4,
    notes: "",
    id: 7,
    name: "Smoke and Mirrors",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Katie Hannon",
    teamSize: 6,
    location: "Croatoa",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Katie_Hannon_Task_Force",
    levels: { min: 30, max: 34 },
    merits: 7,
    notes: "Takes place in Croatoa, considered the fastest TF within the game.",
    id: 8,
    name: "A Tangled Plot",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Manticore",
    teamSize: 7,
    location: "Brickstown",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Manticore_Task_Force",
    levels: { min: 30, max: 35 },
    merits: 32,
    id: 9,
    name: "Following Countess Crey",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Mender Lazarus",
    teamSize: 1,
    location: "Ouroboros",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Mender_Lazarus_Task_Force",
    levels: { min: 30, max: 39 },
    merits: 6,
    notes: "",
    id: 10,
    name: "The 5th Column Overthrow",
    nonSignature: false,
    alignment: "both",
    type: "tf",
  },
  {
    contact: "Numina",
    teamSize: 4,
    location: "Founders' Falls",
    coordinates: "(3778, 4, 712)",
    link: "",
    levels: { min: 35, max: 40 },
    merits: 36,
    notes:
      "Large portion of TF is hunting for enemy groups in different zones.",
    id: 11,
    name: "Soul of the Woodsman",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Imperious",
    teamSize: 6,
    levels: { min: 35, max: 50 },
    merits: 25,
    location: "Cimerora",
    coordinates: "(-1140, 102, 4592.5)",
    link: "https://cityofheroes.fandom.com/wiki/Imperious_Task_Force",
    notes:
      "Takes place in Cimerora. A player entering a non-coop zone will auto-quit from the TF. Hero side icon.jpgVillain side icon.jpg",
    id: 12,
    name: "Time's Arrow",
    nonSignature: true,
    alignment: "both",
    type: "tf",
  },
  {
    contact: "Dr. Quaterfield",
    teamSize: 8,
    levels: { min: 40, max: 44 },
    merits: 122,
    location: "Firebase Zulu",
    coordinates: "(6461, 1092, 1880)",
    link: "https://cityofheroes.fandom.com/wiki/Dr._Quaterfield_Task_Force",
    notes:
      "Majority of TF takes place in the Shadow Shard. Considered one of the Longest TFs in the game (depending on the team).",
    id: 13,
    name: "Explorers and Exploiters",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Sara Moore",
    teamSize: 8,
    levels: { min: 40, max: 50 },
    merits: 64,
    location: "Cascade Archipelago",
    coordinates: "(-152, -1784, 2791.5)",
    link: "https://cityofheroes.fandom.com/wiki/Sara_Moore_Task_Force",
    notes: "Majority of TF takes place in the Shadow Shard.",
    id: 14,
    name: "The Legend of Ruladak",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Justin Augustine",
    teamSize: 8,
    location: "The Chantry",
    coordinates: "(8776, 1537, -5293.5)",
    link: "https://cityofheroes.fandom.com/wiki/Justin_Augustine_Task_Force",
    levels: { min: 44, max: 50 },
    merits: 42,
    notes: "Majority of TF takes place in the Shadow Shard.",
    id: 15,
    name: "The Saga of Faathim",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Faathim the Kind",
    teamSize: 8,
    location: "The Chantry",
    coordinates: "(-30.5, -1995, 4)",
    link: "https://cityofheroes.fandom.com/wiki/Faathim_the_Kind_Task_Force",
    levels: { min: 44, max: 50 },
    merits: 73,
    notes: "Majority of TF takes place in the Shadow Shard.",
    id: 16,
    name: "The Saga of Lanaru",
    nonSignature: true,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "Ms. Liberty",
    teamSize: 8,
    location: "Independence Port",
    coordinates: "(-808.2, 0.7, -5645.0)",
    link: "https://cityofheroes.fandom.com/wiki/Ms._Liberty_Task_Force",
    levels: { min: 45, max: 50 },
    merits: "37+2+2",
    notes: "Reward table choice includes random Synthetic Hamidon Enhancement.",
    id: 17,
    name: "Ms. Liberty Task Force",
    nonSignature: false,
    alignment: "hero",
    type: "tf",
  },
  {
    contact: "The Lady Grey",
    teamSize: 8,
    levels: { min: 45, max: 50 },
    merits: 37,
    location: "Rikti War Zone",
    coordinates: "(328, -1184, -2315.5)",
    link: "https://cityofheroes.fandom.com/wiki/The_Lady_Grey_Task_Force",
    notes:
      "Takes place in Rikti War Zone. A player entering a non-coop zone will auto-quit from the TF. Hero side icon.jpgVillain side icon.jpg",
    id: 18,
    name: "The Lady Grey Task Force",
    nonSignature: false,
    alignment: "both",
    type: "tf",
  },
  {
    contact: "Mender Silos",
    teamSize: 1,
    levels: { min: 46, max: 50 },
    merits: 14,
    location: "Ouroboros",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Mender_Silos_Task_Force",
    notes: "Minor TF with no recipe reward option.",
    id: 19,
    name: "Trading Places",
    nonSignature: false,
    alignment: "both",
    type: "tf",
  },
  {
    contact: "Virgil Tarikoss",
    teamSize: 4,
    levels: { min: 15, max: 20 },
    merits: 13,
    notes: "30-45minutes",
    id: 20,
    name: "The Beast Beneath the Mountain",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "Cap Au Diable",
    coordinates: "(-2800, 238, -528)",
    link: "https://cityofheroes.fandom.com/wiki/Virgil_Tarikoss_Strike_Force",
  },
  {
    contact: "Silver Mantis",
    teamSize: 4,
    levels: { min: 20, max: 25 },
    merits: 42,
    notes:
      "Accessible via the SG mission computer in Villain bases or in Sharkhead neer the Ferry. estimated duration: 1h30 - 2h",
    id: 21,
    name: "Pirates of the Sky",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "SG base--Oracle or Mission Computer",
    coordinates: "",
    link: "https://cityofheroes.fandom.com/wiki/Silver_Mantis_Strike_Force",
  },
  {
    contact: "Strike Force Operative Renault",
    teamSize: 4,
    levels: { min: 25, max: 30 },
    merits: 24,
    notes: 'Randomly grants one of 3 "coral weaponry" temp powers',
    id: 22,
    name: "The Temple of the Waters",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "Sharkhead Isle",
    coordinates: "(-161.3, 64.0, -1050.0)",
    link: "https://cityofheroes.fandom.com/wiki/Operative_Renault_Strike_Force",
  },
  {
    contact: "Mender Tesseract",
    teamSize: 1,
    levels: { min: 25, max: 34 },
    merits: 8,
    notes: "Minor Strike Force, no recipe reward option.",
    id: 23,
    name: "The Spider Weaves his Web",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "Ouroboros",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Mender_Tesseract_Strike_Force",
  },
  {
    contact: "Ice Mistral",
    teamSize: 4,
    levels: { min: 35, max: 40 },
    merits: 26,
    notes:
      "Grants Enchantment of Serafina temp power upon completion, accolade requirement for Megalomaniac.",
    id: 25,
    name: "The Crystal of Serafina",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "St. Martial",
    coordinates: "(0, 0, 0)",
    link: "https://cityofheroes.fandom.com/wiki/Ice_Mistral_Strike_Force",
  },
  {
    contact: "Lord Recluse",
    teamSize: 8,
    levels: { min: 45, max: 50 },
    merits: 25,
    notes:
      "Reward choices includes random Synthetic Hamidon Enhancement, accolade requirement for Marshal.",
    id: 26,
    name: "Lord Recluse's Strike Force",
    nonSignature: false,
    alignment: "villain",
    type: "sf",
    location: "Grandville",
    coordinates: "(2726.5, -55, 934)",
    link: "https://cityofheroes.fandom.com/wiki/Lord_Recluse_Strike_Force",
  },
  {
    contact: "Karsis",
    teamSize: 8,
    levels: { min: 12, max: 15 },
    notes: "Pool B Recipe",
    id: 27,
    name: "Cavern of Transcendence",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "The Hollows",
    coordinates: "(700.6, -32.3, -40)",
    link: "https://cityofheroes.fandom.com/wiki/Cavern_of_Transcendence_Trial",
  },
  {
    contact: "Mairenn MacGregor",
    teamSize: 1,
    levels: { min: 38, max: 40 },
    notes: "Hydra Origin Enhancements Pool C Recipe",
    id: 28,
    name: "Sewer Trial",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "Atlas Park",
    coordinates: "(139, 0, 1017)",
    link: "https://cityofheroes.fandom.com/wiki/Abandoned_Sewers_Trial",
  },
  {
    contact: "Woodsman",
    teamSize: 4,
    levels: { min: 39, max: 41 },
    notes: "Crystal Titan Origin Enhancements Pool C Recipe",
    id: 29,
    name: "Eden Trial",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "Eden",
    coordinates: "(2590, 370, 3515)",
    link: "https://cityofheroes.fandom.com/wiki/Eden_Trial",
  },
  {
    contact: "Jane Hallaway",
    teamSize: 4,
    levels: { min: 24, max: 33 },
    id: 30,
    name: "Hero Respec 1",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "Independence Port",
    coordinates: "(979.5, 64, -4711.5)",
    link: "https://cityofheroes.fandom.com/wiki/Terra_Volta_Respecification_Trial",
  },
  {
    contact: "Captain James Harlan",
    teamSize: 4,
    levels: { min: 34, max: 44 },
    id: 31,
    name: "Hero Respec 2",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "Founders' Falls",
    coordinates: "(1161, 0, 1511)",
    link: "https://cityofheroes.fandom.com/wiki/Terra_Volta_Respecification_Trial",
  },
  {
    contact: "Major Richard Flagg",
    teamSize: 4,
    levels: { min: 44, max: 50 },
    id: 32,
    name: "Hero Respec 3",
    nonSignature: false,
    alignment: "hero",
    type: "trial",
    location: "Peregrine Island",
    coordinates: "(-1623, 0, -3602)",
    link: "https://cityofheroes.fandom.com/wiki/Terra_Volta_Respecification_Trial",
  },
  {
    contact: "Sparcetriel",
    teamSize: 4,
    levels: { min: 24, max: 33 },
    id: 33,
    name: "Villain Respec 1",
    nonSignature: false,
    alignment: "villain",
    type: "trial",
    location: "Nerva Archipelago",
    coordinates: " \t(-1260.5, 11, 6405.5)",
    link: "https://cityofheroes.fandom.com/wiki/Tree_of_Thorns_Respecification_Trial",
  },
  {
    contact: "Trepsarciel",
    teamSize: 4,
    levels: { min: 34, max: 44 },
    id: 34,
    name: "Villain Respec 2",
    nonSignature: false,
    alignment: "villain",
    type: "trial",
    location: "Nerva Archipelago",
    coordinates: "(855, 86, 2476)",
    link: "https://cityofheroes.fandom.com/wiki/Tree_of_Thorns_Respecification_Trial",
  },
  {
    contact: "Ractespriel",
    teamSize: 4,
    levels: { min: 44, max: 50 },
    id: 35,
    name: "Villain Respec 3",
    nonSignature: false,
    alignment: "villain",
    type: "trial",
    location: "Nerva Archipelago",
    coordinates: "(3940, 18, 7076)",
    link: "https://cityofheroes.fandom.com/wiki/Tree_of_Thorns_Respecification_Trial",
  },
];

export default taskForces;
