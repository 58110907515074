import { resistanceBuff, statBuff } from "./buffEffects.js";

export { default as chance } from "./chanceEffect.js";
export { default as damage } from "./damage.js";
export { default as debuff } from "./debuff.js";
export { default as strength } from "./strength.js";
export { default as Ticks } from "./ticks.js";
export { default as taunt } from "./taunt.js";
export { default as protection } from "./protection.js";

export const resistance = resistanceBuff("resistance");

export const maxhitpoints = statBuff("max hit points");
export const heal = statBuff("heal");
export const regenerationrate = statBuff("regeneration");

export const recoveryrate = statBuff("recovery rate");
export const endurance = statBuff("endurance");

export const runspeed = statBuff("run_speed");
export const flyspeed = statBuff("flying_speed");
export const jumpspeed = statBuff("jumping_speed");
export const jumpheight = statBuff("jumping_height");

export const tohit = statBuff("to hit");
export const untouchable = statBuff("untouchable");
export const magnitudeimmobilize = statBuff("immobilize");
export const magnitudeknock = statBuff("knock");
export const magnitudestun = statBuff("stun");
export const magnitudeteleport = statBuff("teleport");
export const threatlevel = statBuff("threat_level");
export const stealthradius = statBuff("stealth_radius");

export const intangible = statBuff("intangible");
export const placate = statBuff("placate");
