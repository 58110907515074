import * as parsers from "./transformerParsers.js";

const powerSlot = parsers.object({
  id: parsers.number().nullable(),
  powerId: parsers.number().nullable(),
  level: parsers.number().nullable(),
});

export const characters = parsers.object({
  id: parsers.number().nullable(),
  name: parsers.string(),
  description: parsers.string().nullable(),
  private: parsers.boolean().nullable(),
  archetype: parsers.object({ id: parsers.number().nullable() }),
  powersets: parsers
    .object({
      primary: parsers.object({ id: parsers.number().nullable() }),
      secondary: parsers.object({ id: parsers.number().nullable() }),
      epic: parsers.object({ id: parsers.number().nullable() }),
    })
    .nullable(),
  link: parsers.string().nullable(),
  build: parsers.object({
    powerSlots: parsers.array(powerSlot),
    pools: parsers.array(parsers.number()),
  }),
});

export const powers = parsers.object({
  powerset_id: parsers.number().nullable(),
  name: parsers.string(),
  short_description: parsers.string().nullable(),
  description: parsers.string().nullable(),
  image: parsers.string().nullable(),
  parent_power_id: parsers.number().nullable(),
  slottable: parsers.boolean(),
  stats: parsers.object().nullable(),
});
