import { getStatForKeyword, isMezType, toSnakeCase } from "utils";

export default function protection() {
  return { run };
}

function run(phrase) {
  // 10.38 hold protection on self
  const [mag, mezType] = this.pop(2, this.hold);

  if (!mag) {
    console.log("NO MAG FOR: ", phrase, mezType);
  }

  this.effect.magnitude = this.parseValue(mag);

  if (isMezType(mezType)) {
    this.effect.stat = getStatForKeyword(toSnakeCase(mezType, "protection"));
  }
}
