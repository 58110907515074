import { alwaysReturn, drillProperties } from "utils/";

function getPropNumber(item, props) {
  return Number(drillProperties(item, props) || 0);
}

function isPowerCorrectLevelForSlot(power, powerSlot) {
  const powerLevel = getPropNumber(power, "stats.availableLevel");
  const slotLevel = getPropNumber(powerSlot, "level");
  return powerLevel <= slotLevel;
}

function mainPowerset() {
  return isPowerCorrectLevelForSlot;
}

function splitPowersByLevel(powerList) {
  return powerList.reduce((acc, cur) => {
    const level = drillProperties(cur, "stats.availableLevel");

    if (!acc[level]) {
      acc[level] = [];
    }

    acc[level].push(cur);
    return acc;
  }, {});
}

function getPowerCountByLevels(powerList) {
  const byLevel = splitPowersByLevel(powerList);
  return (...levels) =>
    levels.reduce(
      (acc, cur) => acc + (byLevel[cur] ? byLevel[cur].length : 0),
      0
    );
}

export const PRIMARY = mainPowerset;
export const SECONDARY = mainPowerset;

export function POOL(powerList) {
  const getCountOfLevels = getPowerCountByLevels(powerList);
  return (power, powerSlot) => {
    if (
      !isPowerCorrectLevelForSlot(power, powerSlot) ||
      getPropNumber(powerSlot, "level") < 4
    ) {
      return false;
    }

    const powerLevel = getPropNumber(power, "stats.availableLevel");
    if (powerLevel >= 14 && getCountOfLevels(1, 4) < 2) {
      return false;
    }

    return true;
  };
}

export function EPIC(powerList) {
  const getCountOfLevels = getPowerCountByLevels(powerList);
  return (power, powerSlot) => {
    if (!isPowerCorrectLevelForSlot(power, powerSlot)) {
      return false;
    }

    const powerLevel = getPropNumber(power, "stats.availableLevel");
    if (powerLevel >= 41 && getCountOfLevels(35) < 1) {
      return false;
    }

    if (powerLevel >= 44 && getCountOfLevels(35, 41) < 2) {
      return false;
    }

    return true;
  };
}

export function INHERENT() {
  return alwaysReturn(true);
}
