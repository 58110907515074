import * as parsers from "./transformerParsers.js";

const powersets = {
  primary: parsers.object({
    id: parsers.number().nullable(),
    name: parsers.string().nullable(),
  }),
  secondary: parsers.object({
    id: parsers.number().nullable(),
    name: parsers.string().nullable(),
  }),
  epic: parsers.object({
    id: parsers.number().nullable(),
    name: parsers.string().nullable(),
  }),
};

const powerSlot = parsers.object({
  id: parsers.number().nullable(),
  powerId: parsers.number().nullable(),
  level: parsers.number().nullable(),
});

export const characters = parsers.object({
  id: parsers.string().nullable(),
  name: parsers.string(),
  description: parsers.string().nullable(),
  private: parsers.boolean().nullable(),
  archetype: parsers.object({
    id: parsers.number().nullable(),
    name: parsers.string().nullable(),
  }),
  powersets: parsers.object(powersets),
  link: parsers.string().nullable(),
  build: parsers.object({
    powerSlots: parsers.array(powerSlot),
    pools: parsers.array(parsers.number()),
  }),
});
