import { getStatForKeyword, toSnakeCase } from "utils";

export default function strength() {
  return { run };
}

function run(phrase) {
  // +50.00%% strength to all damage
  // -75.00%% strength to range
  const [mag] = this.pop(1, this.hold);
  this.effect.magnitude = this.parseValue(mag);
  const [, qualifier, stat] = phrase;

  if (!stat && getStatForKeyword(qualifier, false)) {
    this.effect.stat = getStatForKeyword(qualifier);
  } else if (getStatForKeyword(toSnakeCase(qualifier, stat), false)) {
    this.effect.stat = getStatForKeyword(toSnakeCase(qualifier, stat));
  } else {
    console.error(
      `Could not find STRENGTH for ${phrase}, ${qualifier}, ${stat}`
    );
  }
}
