import { createBrowserHistory } from "history";
const route = createBrowserHistory();
export default route;

export const routeTo = {
  powerset: {
    create(archetype_id, type) {
      route.push(`/archetype/${archetype_id}/create/powerset/${type}`);
    },
    update(archetype_id, powerset_id) {
      route.push(`/archetype/${archetype_id}/powerset/${powerset_id}`);
    },
  },
  power: {
    create(archetype_id, powerset_id) {
      route.push(
        `/archetype/${archetype_id}/powerset/${powerset_id}/create/power`
      );
    },
    update(archetype_id, powerset_id, power_id) {
      route.push(
        `/archetype/${archetype_id}/powerset/${powerset_id}/power/${power_id}`
      );
    },
  },
  origin: {
    create() {
      route.push("/create/origin");
    },
  },
  archetype: {
    create() {
      route.push("/create/archetype");
    },
  },
  pool: {
    create() {
      route.push("/create/pool");
    },
    update(powerset_id) {
      route.push(`/pool/${powerset_id}`);
    },
  },
  poolPower: {
    create(powerset_id) {
      route.push(`/pool/${powerset_id}/create/power`);
    },
    update(powerset_id, power_id) {
      route.push(`/pool/${powerset_id}/power/${power_id}`);
    },
  },
};
