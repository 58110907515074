// @flow
import route from "route";
import {
  shortNames,
  stats,
  keywordToStat,
  damageTypes,
  defenseTypes,
  mezTypes,
  targets,
} from "data";

export function findElementWithDataAttribute(
  element: HTMLElement | Node,
  attribute: String
): HTMLElement | null {
  if (!element) {
    return null;
  }

  let target = element.target || element;

  while (target && !target.dataset?.hasOwnProperty(attribute)) {
    target = target.parentNode;
  }

  return target?.dataset ? target : null;
}

export function findDataAttribute(
  element: HTMLElement | Node,
  attribute: String
): string | null {
  const ele = findElementWithDataAttribute(element, attribute);
  return ele?.dataset[attribute] || null;
}

export function isObject(obj) {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function areEqual(val1, val2) {
  if (isObject(val1) || isObject(val2)) {
    if (!isObject(val1) || !isObject(val2)) {
      return false;
    }

    const keys1 = Object.keys(val1);
    const keys2 = Object.keys(val2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every((val, i) => areEqual(val, keys2[i]));
  }

  if (Array.isArray(val1) || Array.isArray(val2)) {
    if (!Array.isArray(val1) || !Array.isArray(val2)) {
      return false;
    }

    if (val1.length !== val2.length) {
      return false;
    }

    return val1.every((val, i) => areEqual(val, val2[i]));
  }

  if (isNumber(val1) && isNumber(val2)) {
    return Number(val1) === Number(val2);
  }

  return val1 === val2;
}

export function additiveMerge(val1, val2) {
  if (isObject(val1)) {
    // Assuming val1 & val2 are the same type/structure for objects
    const merged = {};
    for (let key in val1) {
      merged[key] = additiveMerge(val1[key], val2[key]);
    }

    return merged;
  }

  if (Array.isArray(val1)) {
    if (Array.isArray(val2)) {
      return [...val1, ...val2];
    } else {
      return [...val1, val2];
    }
  }

  return [val1, val2];
}

function combineTwoClasses(classes, newClass) {
  const space = classes.length ? " " : "";
  return `${classes}${space}${newClass}`;
}

export function combineClasses(...classes) {
  return classes.reduce((acc, c) => (c ? combineTwoClasses(acc, c) : acc), "");
}

const singularExceptions = {};
export function singularize(word) {
  if (singularExceptions[word]) {
    return singularExceptions[word];
  }

  if (word.substring(word.length - 3) === "ies") {
    return word.substring(0, word.length - 3) + "y";
  }

  if (word[word.length - 1] === "s") {
    return word.substring(0, word.length - 1);
  }

  return word;
}

export function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function mergeObjects(obj1, obj2) {
  const log = {};
  const merged = {};

  for (let key in obj2) {
    log[key] = true;
    if (isObject(obj1[key]) && isObject(obj2[key])) {
      merged[key] = mergeObjects(obj1[key], obj2[key]);
    } else {
      merged[key] = obj2[key];
    }
  }

  for (let key in obj1) {
    if (!log[key]) {
      merged[key] = obj1[key];
    }
  }

  return merged;
}

type ObjectToLookUp = { id: number };
type LookupObject = {
  [string]: ObjectToLookUp,
};

export function getLookupByProp(
  prop: string
): (Array<ObjectToLookUp>) => LookupObject {
  return function getLookupFromArr(arr) {
    return arr.reduce((acc, cur) => {
      acc[cur[prop]] = cur;
      return acc;
    }, {});
  };
}

export const getLookupById = getLookupByProp("id");

export function goHome() {
  route.push("/");
}

export function getUrlParameter(paramName) {
  const { pathname } = route.location;
  const path = pathname.split("/");

  for (let i = 0; i < path.length; i++) {
    const arg = path[i];
    if (arg?.toLowerCase?.() === paramName?.toLowerCase?.()) {
      return path[i + 1];
    }
  }
}

export function allowTruthy(x) {
  return !!x;
}

export function arrayize(arr) {
  if (!arr) {
    return [];
  }

  return Array.isArray(arr) ? arr : [arr];
}

export function autoReturn(val) {
  return () => val;
}

export function getStateGetter(state, lookupProp) {
  const lookup = isObject(state)
    ? state
    : lookupProp
    ? getLookupByProp(lookupProp)(state)
    : getLookupById(state);

  const filter = (f) =>
    state.filter((item) => {
      for (let key in f) {
        if (!areEqual(drillProperties(item, key), f[key])) {
          return false;
        }
      }
      return true;
    });

  return (lookupValue, prop, log) => {
    return lookupValue !== undefined
      ? isObject(lookupValue)
        ? prop
          ? drillProperties(filter(lookupValue), prop)
          : filter(lookupValue)
        : drillProperties(lookup[lookupValue], prop)
      : state;
  };
}

export function noFunc() {}

export function stopProp(e) {
  e.stopPropagation();
}

export function alwaysReturn(val) {
  return () => val;
}

export function isNumber(x) {
  return !isNaN(Number(x)) && !isNaN(parseInt(x, 10));
}

export function stringArrayToLookup(arr) {
  return arr.reduce((acc, str) => {
    acc[str] = true;
    return acc;
  }, {});
}

export function verifyInLookup(lookup) {
  return (value) => lookup[value] || false;
}

export function getIsInArray(arr) {
  if (!Array.isArray(arr)) {
    arr = [arr];
  }

  const lookup = stringArrayToLookup(arr);
  const isInLookup = verifyInLookup(lookup);
  return isInLookup;
}

const timeMeasurements = {
  s: true,
  m: true,
  h: true,
};

export function isTimeValue(str) {
  if (!str || typeof str !== "string") {
    return false;
  }

  let foundNumber = false;
  let foundTimeMeasurement = false;
  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (!isNumber(char) && !timeMeasurements[char] && char !== ".") {
      return char === "(" ? foundNumber && foundTimeMeasurement : false;
    } else if (isNumber(char)) {
      foundNumber = true;
    } else if (timeMeasurements[char]) {
      foundTimeMeasurement = true;
    }
  }

  return foundNumber && foundTimeMeasurement;
}

export function drillProperties(obj, props) {
  if (!props || !props.length) {
    return obj;
  }

  return props.split(".").reduce((acc, cur) => (acc ? acc[cur] : acc), obj);
}

export function alphabeticalSort(r) {
  return r.sort((a, b) => a.name > b.name);
}

export function getSortByProp(sortProp, tieBreaker, descending = false) {
  const getValue = (obj) =>
    typeof sortProp === "function"
      ? sortProp(obj)
      : drillProperties(obj, sortProp);

  const getTieBreakerValue = (obj) =>
    typeof tieBreaker === "function"
      ? tieBreaker(obj)
      : tieBreaker
      ? drillProperties(obj, tieBreaker)
      : drillProperties(obj, "id");

  return (a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);

    const aCompare = aValue === bValue ? getTieBreakerValue(a) : aValue;
    const bCompare = aValue === bValue ? getTieBreakerValue(b) : bValue;

    const greaterThan = descending ? bCompare : aCompare;
    const lessThan = descending ? aCompare : bCompare;

    return greaterThan > lessThan ? 1 : -1;
  };
}

export const levelSort = getSortByProp((power) =>
  Number(drillProperties(power, "stats.availableLevel") || 0)
);

function isEnvVarTrue(envVar) {
  return envVar === true || envVar === "true";
}

export function isAdminApp() {
  const isAdmin =
    isEnvVarTrue(process.env.REACT_APP_IS_ADMIN) ||
    isEnvVarTrue(process.env.STORYBOOK_IS_ADMIN);

  document.title = isAdmin ? "Stinkfoot Regulators" : "Stinkfoot";
  return isAdmin;
}

export function toCamelCase(...strings) {
  return strings.reduce((acc, str) => {
    if (str) {
      const [first, ...rest] = str.split(" ");
      if (first) {
        const casing = acc.length ? "toUpperCase" : "toLowerCase";
        acc += first[0][casing]() + first.substring(1);
      }
      if (rest.length) {
        acc += rest
          .map((str) => str[0].toUpperCase() + str.substring(1))
          .join("");
      }
    }

    return acc;
  }, "");
}

export function toSnakeCase(...strings) {
  return strings.reduce((acc, str) => {
    if (str) {
      acc.length && (acc += "_");
      for (let i = 0; i < str.length; i++) {
        const char = str[i];
        acc += char === " " ? "_" : char.toLowerCase();
      }
    }

    return acc;
  }, "");
}

export function camelCaseToDisplay(str) {
  let capital = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (i === 0) {
      capital += char.toUpperCase();
    } else if (char === char.toUpperCase()) {
      capital += ` ${char}`;
    } else {
      capital += char;
    }
  }

  return capital;
}

export function runAll(...funcs) {
  return (...args) => {
    return funcs.map((cb) => cb && cb(...args));
  };
}

export function getFilteredArray(arr, transformCB) {
  return (filters, data) => {
    return arr.reduce((acc, item, i) => {
      if (isObject(filters)) {
        for (let filter in filters) {
          const expectedType = getDataType(filters[filter]);
          const actualValue = drillProperties(item, filter);
          const convertedActual = convertToDataType(actualValue, expectedType);
          if (filters[filter] === null && actualValue !== null) {
            return acc;
          } else if (
            filters[filter] === undefined ||
            (typeof filters[filter] === "number" && isNaN(filters[filter]))
          ) {
            return acc;
          } else if (convertedActual !== filters[filter]) {
            return acc;
          }
        }

        acc.push(transformCB ? transformCB(item, data) : item);
      } else if (typeof filters === "function") {
        const shouldAdd = filters(item, i, arr);
        shouldAdd && acc.push(transformCB ? transformCB(item, data) : item);
      }

      return acc;
    }, []);
  };
}

export function convertToDataType(val, type) {
  if (val === null) {
    return null;
  }

  if (type === "number") {
    return Number(val);
  }

  if (type === "string") {
    return val.toString?.();
  }

  if (type === "object" && typeof val === "string") {
    try {
      return JSON.parse(val);
    } catch (err) {
      return;
    }
  }
}

export function getDataType(val) {
  if (isObject(val)) {
    return "object";
  }

  if (Array.isArray(val)) {
    return "array";
  }

  return typeof val;
}

export function isLessThan(equalTo, item1, item2) {
  return equalTo ? item1 <= item2 : item1 < item2;
}

export function isGreaterThan(equalTo, item1, item2) {
  return equalTo ? item1 >= item2 : item1 > item2;
}

export function isEqualTo(item1, item2) {
  return item1 === item2;
}

const operators = {
  "<": isLessThan.bind(this, false),
  "<=": isLessThan.bind(this, true),
  "=": isEqualTo,
  "==": isEqualTo,
  "===": isEqualTo,
  ">": isGreaterThan.bind(this, false),
  ">=": isGreaterThan.bind(this, true),
};

export function compare(item1, op, item2) {
  const operation = operators[op];
  if (!operation) {
    throw new Error(
      `Invalid compare operation ${op}. Expected: ${Object.keys(operators)}`
    );
  }

  if (isNumber(item1) && isNumber(item2)) {
    return operation(Number(item1), Number(item2));
  }

  return operation(item1, item2);
}

export function deepClone(data) {
  if (isObject(data)) {
    const cloned = {};
    for (let key in data) {
      cloned[key] = deepClone(data[key]);
    }
    return cloned;
  }

  if (Array.isArray(data)) {
    return data.map(deepClone);
  }

  return data;
}

export function splitArrayIntoNths(arr, n) {
  const maxSize = Math.round(arr.length / n);
  return arr.reduce(
    (acc, cur) => {
      if (acc[acc.length - 1].length >= maxSize) {
        acc.push([]);
      }

      acc[acc.length - 1].push(cur);
      return acc;
    },
    [[]]
  );
}

export function getShortStatName(statName) {
  return shortNames[statName] || "Proc";
}

export function debounce(cb, config) {
  let timeout = null;
  const promise = { resolve: null, reject: null };

  return function (...args) {
    timeout && clearTimeout(timeout);
    promise.resolve?.();
    return new Promise((resolve, reject) => {
      if (config.leading && !timeout) {
        resolve(cb.apply(this, args));
      }

      timeout = setTimeout(() => {
        if (config.trailing || !config.leading) {
          resolve(cb.apply(this, args));
        }
        timeout = null;
      }, config.wait);

      promise.resolve = resolve;
      promise.reject = reject;
    });
  };
}

export function parseTime(time) {
  if (!time) {
    return false;
  }

  const index = time.indexOf("(");
  if (index > -1) {
    time = time.substring(0, index);
  }

  const { h, m, s } = time.split("").reduce(
    (acc, cur) => {
      if (cur !== " ") {
        if (isNumber(cur) || cur === ".") {
          acc.hold += cur;
        } else if (acc.hasOwnProperty(cur)) {
          acc[cur] = parseFloat(acc.hold);
          acc.hold = 0;
        } else {
          console.log("FAILED ON:", cur);
          return false;
        }
      }

      return acc;
    },
    { h: 0, m: 0, s: 0, hold: 0 }
  );

  const timeInSeconds = h * 60 * 60 + m * 60 + s;
  return isNaN(timeInSeconds) ? false : `${timeInSeconds}s`;
}

export function parseNumber(v) {
  const value = Number(v);
  return !isNaN(value) ? value : false;
}

export function parsePercent(v) {
  const isPercent = v.includes("%");
  return isPercent ? `${parseFloat(v)}%` : false;
}

export function parseValue(v) {
  if (typeof v === "number") {
    return v;
  }

  if (v.includes("%")) {
    return parsePercent(v);
  }

  if (v.includes("/s")) {
    return v;
  }

  if (isTimeValue(v)) {
    return parseTime(v);
  }

  if (isNumber(v)) {
    return parseNumber(v);
  }

  return v;
}

export function parseStatValue(value, defaultUnit) {
  const mag = parseFloat(value === null ? 0 : value);
  if (isNaN(mag)) {
    return { mag: -1, unit: " N/A" };
  }

  if (defaultUnit || typeof value !== "string") {
    return { mag, unit: defaultUnit || "" };
  }

  let firstNonNumber = -1;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char !== "." && isNaN(Number(char)) && firstNonNumber === -1) {
      firstNonNumber = i;
    }
  }

  if (firstNonNumber === -1) {
    return { mag, unit: "" };
  }

  const unit = value.substring(firstNonNumber);
  return { mag, unit };
}

function isKeywordInLookup(lookup, keyword) {
  return !!lookup[keyword?.toLowerCase?.()];
}

const isInStats = isKeywordInLookup.bind(this, stats);
export function isStat(value) {
  return Array.isArray(value) ? value.every(isInStats) : isInStats(value);
}

export function getStatForKeyword(keyword, expected = true) {
  const exists = isKeywordInLookup(keywordToStat, keyword);
  if (!exists && expected) {
    console.error(`No stat found for ${keyword}`);
  }

  return keywordToStat[keyword.toLowerCase()];
}

export function isDamageType(keyword) {
  return isKeywordInLookup(damageTypes, keyword);
}

export function isDefenseType(keyword) {
  return isKeywordInLookup(defenseTypes, keyword);
}
export const isMezType = getIsInArray(mezTypes);
export const isTarget = getIsInArray(targets);

export function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
}

export { default as powerParser } from "./powerParser";
export * as calls from "./calls/";
export * as stateSubscriptions from "./stateSubscriptions";
export { default as getMutableReducer } from "./getMutableReducer.js";
export * as POWER_PLUGINS from "./powerPlugins/";
