import ReactDOM from "react-dom";

import { isAdminApp } from "utils/index.js";

const styles = isAdminApp()
  ? import("./styles/admin.scss")
  : import("./styles/stinkfoot.scss");

styles.then(() => {
  import("./App.js").then(({ default: App }) => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
});
